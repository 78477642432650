/*eslint-disable*/
// import Logger from "../../Lib/Logger";
// import moment from "moment-timezone";
import moment from 'moment'

import momentJalali from "moment-jalaali";

export default {
    data() {
        return {
            rules: {
                required: value => !!value || 'پر گردن این فیلد اجباری است',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailRules: [
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'ایمیل را با ساختار مناسب وارد نمایید'
                ]
            },
        }
    },
    methods: {
        prepareQuery(query) {
            // if (query) {
            //     if (query.hasOwnProperty('start_at')) {
            //         console.log(query)
            //         let year = moment(query.start_at).format('YYYY')
            //         let month = moment(query.start_at).format('MM')
            //         let day = moment(query.start_at).format('d')
            //         // 2022-10-03
            //         query.start_at = this.persianToCalendars(1400, 12, 19)
            //         console.log(moment(query.start_at).utc().format('y/mm/dd'),'svdsdsvsdvsdv')
            //         // query.start_at = year + '/' + month + "/" + day;
            //         // query.start_at = moment(query.start_at).tz('CET').get()
            //     }
            // }
            return query;
        },
        requesting(type = null, name = null, paramsQuery = {}, body = {}, header = {}) {
            return this.$store.dispatch('generalRequest', {
                type: type,
                name: name,
                paramsQuery: paramsQuery,
                body: body,
                headers: header,
            })
        },
        // logger(data) {
        //     new Logger().log(data)
        // },
        filteringTime(date, format = "Y/M/D") {
            let options = {year: 'numeric', month: 'long', day: 'numeric'};
            return new Date(date).toLocaleDateString('fa-IR', options)
        },
        persianToCalendars(year, month, day, op = {}) {
            let options = {year: 'numeric', month: 'numeric', day: 'numeric'};
            const formatOut = gD => "toCal" in op ? (op.calendar = op.toCal, new Intl.DateTimeFormat(op.locale ?? "en", op).format(gD)) : gD,
                dFormat = new Intl.DateTimeFormat('en-u-ca-persian', {
                    // dateStyle: 'short',
                    timeZone: "UTC",
                    year: "numeric",
                    month: "numeric",
                    day: 'numeric'
                });
            let gD = new Date(Date.UTC(2000, month, day));
            gD = new Date(gD.setUTCDate(gD.getUTCDate() + 226867));
            const gY = gD.getUTCFullYear() - 2000 + year;
            gD = new Date(((gY < 0) ? "-" : "+") + ("00000" + Math.abs(gY)).slice(-6) + "-" + ("0" + (gD.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + (gD.getUTCDate())).slice(-2));
            let [pM, pD, pY] = [...dFormat.format(gD).split("/")], i = 0;
            gD = new Date(gD.setUTCDate(gD.getUTCDate() +
                ~~(year * 365.25 + month * 30.44 + day - (pY.split(" ")[0] * 365.25 + pM * 30.44 + pD * 1)) - 2));
            while (i < 4) {
                [pM, pD, pY] = [...dFormat.format(gD).split("/")];
                if (pD == day && pM == month && pY.split(" ")[0] == year) return new Date(gD).toLocaleDateString('en-US', options)
                gD = new Date(gD.setUTCDate(gD.getUTCDate() + 1));
                i++;
            }
            return new Date(gD).toLocaleDateString('en-US', options)
        },
    },
    filters: {
        typesFilter(type) {
            switch (type) {
                case "article":
                    return 'مقالات'
                case "collection":
                    return 'مجموعه‌ها'
                case 'book':
                    return 'کتاب‌ها'
            }
        },
        emojiFilter(type) {
            switch (type) {
                case "article":
                    return '📝'
                case "collection":
                    return '🗂️'
                case 'book':
                    return '📕'
            }
        },
        typeFilter(type) {
            switch (type) {
                case "article":
                    return 'مقاله'
                case "collection":
                    return 'مجموعه'
                case 'book':
                    return 'کتاب‌'
            }
        },
        filteringTime(date, format = "Y/M/D") {
            let options = {year: 'numeric', month: 'numeric', day: 'numeric'};
            return new Date(date).toLocaleDateString('fa-IR', options)
        },
        filteringTimeWithTime(date, format = "Y/M/D") {
            let options = {
                year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric',
                minute: 'numeric',
            };
            return new Date(date).toLocaleDateString('fa-IR', options)
        }
    },

}
const baseUrl = 'admin/category'
/*eslint-disable*/
export default [
    {
        name: "getAllCategories",
        path: baseUrl + "?pagination=:pagination&page=:page",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getAllCategoriesFroContent",
        path: baseUrl ,
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "insertCategory",
        path: baseUrl + "/insert",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "updateCategory",
        path: baseUrl + "/edit/:id",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getCategory",
        path: baseUrl + "/:id",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "deleteCategory",
        path: baseUrl + "/delete/:id",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
]
<template>
  <div>
    <div class="contentArea">
      <v-select outlined dense label="نوع کتاب" v-model="content.type" :items="bookTypes" item-value="key"
                item-text="title"></v-select>
      <v-text-field dir="ltr" dense v-model="content['path']" outlined label="لینک کتاب"></v-text-field>
      <v-file-input outlined dense label="فایل کتاب" v-model="content['book_file']"/>
      <a :href="content.file" style="text-decoration: none">
        <v-btn v-if="content.file" class="mb-7" elevation="0">
          دانلود فایل کتاب
          <v-icon class="mr-2">mdi-download</v-icon>
        </v-btn>
      </a>
      <v-text-field v-model="content['author_info']" dir="ltr" dense outlined label="نام نویسنده: "></v-text-field>
      <v-text-field v-model="content['page_count']" dir="ltr" dense outlined label="تعداد صفحات : "></v-text-field>
    </div>
    <TextEditor :content="content"/>
  </div>
</template>

<script>
/*eslint-disable*/
import TextEditor from "@/components/main/TextEditor";

export default {
  components: {TextEditor},
  props: {
    content: Object,
  },
  // date(){
  //   return{

  //   }
  // }
  data() {
    return {
      test: this.content,
      bookTypes: [
        {
          key: 'internal',
          title: 'داخلی'
        },
        {
          key: 'external',
          title: 'خارجی'
        }
      ]
    }
  },
  watch: {}
}
</script>
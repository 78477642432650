<template>
  <div>
    <v-autocomplete
        chips
        v-if="contents"
        multiple
        v-model="content['contents']"
        :items="contents" item-text="title"
        item-value="id"
        outlined
        class="mt-15"
        label="محتوا‌های این مجموعه"
    />
    <TextEditor :content="content"/>
  </div>
</template>

<script>
/*eslint-disable*/
import VueFroala from 'vue-froala-wysiwyg';
import TextEditor from "@/components/main/TextEditor";
import {EventBus} from "@/event-bus";

export default {
  components: {TextEditor},
  props: {
    content: Object,
  },
  data() {
    return {
      contents: null,
    }
  },
  methods: {
    getContents() {
      EventBus.$emit('setLoading', true)
      this.requesting('content', 'getAllContentWithoutPagination').then(resp => {
        this.contents = resp.data.contents
        console.log(this.content,'121212112')
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {
    this.getContents()
  }
}
</script>
<template>
  <div>
    <v-dialog v-model="modalSend" width="500px">
      <v-card>
        <v-card-title class="green">
          ارسال خبرنامه
        </v-card-title>
        <v-card-text class="mt-10">
          <v-text-field v-model="email  " persistent-hint hint="در صورت خالی بودن این فیلد برای همه ارسال می‌شود"
                        label="ایمیل" outlined
                        dense></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" @click="sendNewsletter">
            ارسال
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="boxAdmin pa-4">
      <div class="d-flex justify-space-between">
        <h2>
          خبرنامه‌ها
        </h2>
        <div>
          <v-btn color="red" to="/dashboard/newsletters/add">
            افزودن
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <v-data-table
          v-if="newsletters"
          :headers="headers"
          :items="newsletters"
          class="blue"
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item.created_at="{item}">
          {{ item.created_at | filteringTime }}
        </template>
        <template v-slot:item.action="{item}">
          <v-btn fab color="green" x-small elevation="0" :to="`/dashboard/newsletters/${item.id}`">
            <v-icon>
              mdi-chart-bar
            </v-icon>
          </v-btn>
          <v-btn fab class="mr-2" x-small elevation="0" :to="`/dashboard/newsletters/${item.id}/edit`">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn fab class="mr-2" color="red" x-small elevation="0" @click="deleteNewsletters(item.id)">
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
          <v-btn fab class="mr-2" color="accent" x-small elevation="0" @click="modalSend = true;newsletter_id=item.id">
            <v-icon>
              mdi-send
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
/*eslint-disable*/
export default {
  data() {
    return {
      newsletters: [],
      modalSend: false,
      newsletter_id: null,
      headers: [
        {
          text: 'کد ',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'نام ',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {
          text: 'تعداد دانلود ',
          align: 'start',
          sortable: false,
          value: 'visit_count',
        },
        {
          text: 'تعداد ارسال',
          align: 'start',
          sortable: false,
          value: 'send_count',
        },
        {
          text: 'تاریخ ایجاد',
          align: 'start',
          sortable: false,
          value: 'created_at',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
      email: null,
    }
  },
  methods: {
    getNewsletters() {
      EventBus.$emit('setLoading', true)
      this.requesting('newsletter', 'getNewsletters',).then((resp) => {
        this.newsletters = resp.data.newsletters
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    deleteNewsletters(id) {
      EventBus.$emit('setLoading', true)
      this.requesting('newsletter', 'deleteNewsletters', {id}).then((resp) => {
        EventBus.$emit('notify', {color: 'green', text: 'خبرنامه حدف شد', type: "success"})
        this.getNewsletters()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    sendNewsletter() {
      EventBus.$emit('setLoading', true)
      let payload = {}
      if (this.email && this.email != '') {
        payload.email = this.email
      }
      this.requesting('newsletter', 'sendNewsletter', {id: this.newsletter_id}, payload).then((resp) => {
        EventBus.$emit('notify', {color: 'green', text: 'خبرنامه ارسال شد', type: "success"})
        this.getNewsletters()
        this.modalSend = false
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {
    this.getNewsletters()
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div class="boxAdmin pa-4" v-if="newsletter">
      <h2>
        ویرایش خبرنامه
      </h2>
      <v-form ref="edit" class="mt-10">
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field label="تیتر" v-model="newsletter.title" dense outlined></v-text-field>
            <v-textarea label="توضیحات" v-model="newsletter.description" dense outlined></v-textarea>
            <v-file-input label="فایل خبرنامه" v-model="file" dense outlined></v-file-input>
            <template v-if="newsletter.file">
              <a :href="newsletter.file.url" target="_blank">
                دانلود فایل
              </a>
            </template>
          </v-col>
        </v-row>
      </v-form>
      <v-btn @click="editNewsletter" class="mt-5 red">
        ویرایش
      </v-btn>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
/*eslint-disable*/
export default {
  data() {
    return {
      newsletter: null,
      file: null,
    }
  },
  watch: {
    file() {
      let payload = new FormData()
      payload.append('file', this.file)
      this.requesting('newsletter', 'uploadFile', {}, payload).then((item) => {
        this.newsletter.file_id = item.id
        console.log(this.newsletter, '12121121')
      })
    }
  },
  methods: {
    getNewsletter() {
      EventBus.$emit('setLoading', true)
      this.requesting('newsletter', 'getNewsletter', {id: this.$route.params.id},).then((resp) => {
        this.newsletter = resp.data.newsletter
        this.newsletter.logs.forEach((log) => {
          if (log.is_seen) {
            this.opened_email++;
          }
        })
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    editNewsletter() {
      EventBus.$emit('setLoading', true)
      this.requesting('newsletter', 'editNewsletter', {id: this.$route.params.id}, this.newsletter).then((resp) => {
        EventBus.$emit('notify', {color: 'green', text: 'خیرنامه ویرایش شد', type: "success"})
        this.getNewsletter()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {
    this.getNewsletter()
  }
}
</script>

<style scoped>
.didntOpened {
  background: red;
  width: 100px;
  color: white;
  text-align: center;
  font-size: 12px;
  border-radius: 10px;
  font-weight: bolder;
}

.sentState {
  background: green;
  width: 100px;
  color: white;
  text-align: center;
  font-size: 12px;
  border-radius: 10px;
  font-weight: bolder;
}

.pendingState {
  background: cornflowerblue;
  width: 100px;
  color: white;
  text-align: center;
  font-size: 12px;
  border-radius: 10px;
  font-weight: bolder;
}
</style>
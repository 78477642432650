const baseUrl = 'admin/newsletter/'
/*eslint-disable*/
export default [
    {
        name: "getNewsletters",
        path: baseUrl + "all",
        method: "get",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getNewsletter",
        path: baseUrl + ":id/get",
        method: "get",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "uploadFile",
        path: "admin/upload-file",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "editNewsletter",
        path: baseUrl + ":id/edit",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "addNewsletter",
        path: baseUrl + "insert",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "sendNewsletter",
        path: baseUrl + ":id/send",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "deleteNewsletters",
        path: baseUrl + ":id/delete",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
]
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    rtl:true,
    theme: {
        dark:true,
        themes: {
            light: {
                primary: '#3f51b5',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
            dark:{
                primary: '#e82a2a',
                'secondPrimary': '#39475d',
                'blue':'#1a1d24'
            }
        },
    },
});

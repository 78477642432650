const baseUrl = 'main/'
/*eslint-disable*/
export default [
    {
        name: "getArticles",
        path: baseUrl + "content/:type/all",
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "getArticle",
        path: baseUrl + "content/:slug",
        method: "get",
        loading: true,
        alert: true,
        commits: [
            // {
            //     name: 'SET_PRIMARY_PICTURE_PATH',
            //     key: 'loggedIn',
            //     data: true,
            //     localStoragePersist: true
            // },
            {
                name: 'SET_PRIMARY_PICTURE_PATH',
                key: 'primaryPicture',
                dataClosure: (resp, state) => {
                    return resp.data.primary_picture.path
                },
                localStoragePersist: true,
                ttl: 3000,
            },
            // {
            //     name: 'SET_PRIMARY_PICTURE_PATH',
            //     key: 'primaryPicture',
            //     dataPath: 'data.primary_picture.path',
            //     localStoragePersist: true,
            //     debug: false,
            //     before: () => {
            //     },
            //     after: () => {
            //     },
            // },

        ],
        defaultHeader: {},
        defaultBody: {},
    },
]
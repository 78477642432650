<template>
  <div>
    <v-dialog v-model="deleteModal" width="500px">
      <v-card>
        <v-card-title class="warning">
          اخطار
        </v-card-title>
        <v-card-text class="mt-10">
          <p class="alertText">
            ایا از حذف این مورد اطمینان دارید؟
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="deleteing"> حذف کنید</v-btn>
          <v-btn color="primary" @click="cancel" outlined>لغو</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props:{
    deleteModal:Boolean,
    deleteID:Number,
  },
  methods:{
    deleteing(){
      this.$emit('delete',this.deleteID)
    },
    cancel(){
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>
.alertText{
  font-size: 15pt;
}
</style>
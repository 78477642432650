<template>
  <div id="app">
    <froala class="" id="edit" :tag="'textarea'" :config="config" v-model="content['body']"></froala>
  </div>
</template>

<script>
/*eslint-disable*/
import VueFroala from 'vue-froala-wysiwyg';

export default {
  props: {
    content: Object,
  },
  name: 'TextEditor',
  data() {
    return {
      config: {
        placeholderText: 'Edit Your Content Here!',
        heightMin: 500,
        direction: 'rtl',
        imageUploadURL: process.env.VUE_APP_BASE_URL + 'main/upload/content-image',
        imageUploadMethod: 'POST',
        imageAllowedTypes: ['jpeg', 'jpg', 'png'],
        events: {
          initialized: function () {
            console.log('initialized')
          }
        }
      },
      model: 'Edit Your Content Here!'
    }
  },
}
</script>
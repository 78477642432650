const baseUrl = 'admin/users/'
/*eslint-disable*/
export default [
    {
        name: "getUsers",
        path: baseUrl + "search",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getAdmins",
        path: baseUrl + "admins",
        method: "get",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
]
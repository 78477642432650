const baseUrl = 'admin/content/'
/*eslint-disable*/
export default [
    {
        name: "getLastContent",
        path: baseUrl + "?orderBy=id&limit=:limit",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getPopularContent",
        path: baseUrl + "?popular=true&limit=:limit",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getAllContents",
        path: "admin/content?pagination=:pagination&page=:page",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getAllContentWithoutPagination",
        path: "admin/content",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "insertContent",
        path: baseUrl + "insert/:type",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "insertContent",
        path: baseUrl + "insert/:type",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getContent",
        path: "admin/content/:id",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "updateContent",
        path: "admin/content/:id/edit",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "changeStatusContent",
        path: "admin/content/:id/change-status",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "deleteStatusContent",
        path: "admin/content/:id/delete",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
]
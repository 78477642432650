<template>
  <div class="">
    <OverviewSection/>
  </div>
</template>

<script>
import OverviewSection from "@/components/dashboard/overviewSection";
export default {
  name:"indexDashboard",
  components: {OverviewSection}
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div class="contentArea">
      <v-select outlined dense  label="نوع کتاب" v-model="content.type" :items="bookTypes" item-value="key" item-text="title"></v-select>
      <v-fade-transition mode="out-in">
        <v-text-field dir="ltr" dense  v-model="content['path']" v-if="content['type'] == 'external'" outlined label="لینک کتاب"></v-text-field>
        <v-file-input outlined dense  v-else-if="content['type'] == 'internal'" label="فایل کتاب" v-model="content['book_file']"/>
      </v-fade-transition>
      <v-text-field v-model="content['author_info']" dir="ltr" dense   outlined label="نام نویسنده: "></v-text-field>
      <v-text-field v-model="content['page_count']" dir="ltr" dense   outlined label="تعداد صفحات : "></v-text-field>
    </div>
    <TextEditor :content="content" />
  </div>
</template>

<script>
/*eslint-disable*/
import TextEditor from "@/components/main/TextEditor";

export default {
  components: {TextEditor},
  props:{
    content:Object,
  },
  // date(){
  //   return{

  //   }
  // }
  data() {
    return {
      test:this.content,
      bookTypes:[
        {
          key:'internal',
          title:'داخلی'
        },
        {
          key:'external',
          title:'خارجی'
        }
      ]
    }
  },
  watch:{

  }
}
</script>
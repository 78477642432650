<template>
  <div>
    <div class="boxAdmin pa-4">
      <h2>
        ایجاد دسته‌بندی
      </h2>
      <div class="contentArea mt-10">
        <v-form ref="addCategory">
          <v-text-field :rules="[rules.required]" v-model="category.name" outlined dense
                        label="کلید :"></v-text-field>
          <v-text-field :rules="[rules.required]" v-model="category.title" outlined dense
                        label="نام :"></v-text-field>
          <!--          <v-text-field v-model="category.color" outlined dense type="color" label="رنگ :"></v-text-field>-->
          <!--          <v-file-input v-model="category.primary_picture" label="عکس شاخص" outlined dense></v-file-input>-->
          <v-select outlined dense v-model="category.show_in_index" item-text="title" item-value="key" :items="show_in_index_options"
                    label="نمایش در صفحه اصلی"></v-select>
          <v-btn color="primary" @click="storeCategory">افزودن دسته‌بندی</v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      category: {
        show_in_index: false,
      },
      show_in_index_options: [
        {
          title: 'نمایش در صفحه اصلی',
          key: true,
        },
        {
          title: 'بدون نمایش',
          key: false,
        }
      ]
    }
  },
  methods: {
    storeCategory() {
      if (this.$refs.addCategory.validate()) {
        EventBus.$emit('setLoading', true)
        // Object.keys(this.category).forEach(item => {
        //   payload.append(item, this.category[item])
        // })
        this.requesting('category', 'insertCategory', {}, this.category).then(() => {
          EventBus.$emit('notify', {color: 'green', text: 'دسته‌بندی اضافه شد', type: "success"})
          this.$router.push({name: "categoryList"})
        }).catch(() => {
          EventBus.$emit('notify', {color: 'red', text: 'فرایند با مشکل مواجه شد', type: "error"})
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    }
  }
}
</script>

<style scoped>
.contentArea {
  width: 500px;
}
</style>
<template>
  <div>
    <v-dialog v-model="editChartFilter" width="400px">
      <v-card>
        <v-card-title>
          فلیتر اطلاعات نمودار
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-text-field v-model="year" dense outlined label="سال :"></v-text-field>
          <v-select
              dense
              outlined
              label="نوع محتوا"
              v-model="type"
              :items="types"
              item-value="key"
              item-text="title"
          ></v-select>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn color="primary" @click="getDataForChart">اعمال فیلتر</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-fade-transition mode="out-in">
      <v-row :key="1" v-if="overview">
        <v-col cols="12" lg="3" class="">
          <div class="boxAdmin text-center mx-1">
            <div class="labelNumber primary--text">
              {{ overview.visitors }}
            </div>
            <div class="labelStatistics">
              آمار بازدید امروز
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="3" class="">
          <div class="boxAdmin text-center mx-1">
            <div class="labelNumber primary--text">
              {{ overview.content.book }}
            </div>
            <div class="labelStatistics">
              تعداد کتاب‌ها
              {{ 'book' | emojiFilter }}
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="3">
          <div class="boxAdmin text-center mx-1">
            <div class="labelNumber primary--text">
              {{ overview.content.article }}

            </div>
            <div class="labelStatistics">
              تعداد مقالات
              {{ 'article' | emojiFilter }}
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="3">
          <div class="boxAdmin text-center mx-1">
            <div class="labelNumber primary--text">
              {{ overview.content.collection }}
            </div>
            <div class="labelStatistics">
              <div class="labelStatistics">
                تعداد مجموعه‌ها
                {{ 'collection' | emojiFilter }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row :key="2" v-else>
        <v-col cols="12" lg="3" v-for="n in 4" :key="n">
          <div class="loader boxAdminLoader"></div>
        </v-col>
      </v-row>
    </v-fade-transition>
    <v-row class="mt-4">
      <v-col cols="12" lg="6">
        <v-fade-transition mode="out-in">
          <div class="boxAdmin" v-if="series[0].data.length > 0" :key="11">
            <template v-if="type">
              <v-alert class="text-center" color="primary">
                نمودار برای
                {{ type | typesFilter }}
                {{ type | emojiFilter }}
              </v-alert>
            </template>
            <vue-apex-charts type="line" height="350" :options="chartOptions" :series="series"/>
            <div class="text-center">
              <v-btn color="primary" small @click="editChartFilter = !editChartFilter">
                ویرایش فیلتر‌ها
                <v-icon class="mr-2" small>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else :key="22">
            <div class="loader boxAdminLoader " style="height: 350px"></div>
          </div>
        </v-fade-transition>
      </v-col>
      <v-col cols="12" lg="6">
        <v-fade-transition mode="out-in">
          <div class="boxAdmin text-center" v-if="lastContent" :key="12">
            <h2>آخرین محتوا‌ها</h2>
            <v-data-table
                v-if="lastContent"
                :headers="headers"
                :items="lastContent"
                class="blue"
                disable-pagination
                hide-default-footer
            >
              <template v-slot:item.action="{ item }">
                <v-btn elevation="0" fab x-small :to="`/dashboard/content/${item.id}`">
                  <v-icon size="small">mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
          <div v-else class="loader boxAdminLoader " style="height: 100%" :key="13"></div>
        </v-fade-transition>
      </v-col>
    </v-row>
    <div class="mt-10 boxAdmin text-center mb-10">
      <v-fade-transition mode="out-in">
        <div v-if="popularArticle" :key="14">
          <h2>پربازدید ترین محتوا‌ها</h2>
          <v-data-table
              v-if="popularArticle"
              :headers="headers"
              :items="popularArticle"
              class="blue"
              disable-pagination
              hide-default-footer
          >
            <template v-slot:item.action="{ item }">
              <v-btn elevation="0" fab x-small :to="`/dashboard/content/${item.id}`">
                <v-icon size="small">mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
        <div :key="15" v-else class="loader boxAdminLoader" style="height: 250px"></div>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import VueApexCharts from 'vue-apexcharts'
import login from "@/views/auth/login";

export default {
  components: {VueApexCharts},
  data() {
    return {
      values: [],
      labels: [],
      overview: null,
      type: null,
      types: [
        {
          key: "article",
          title: "مقالات"
        },
        {
          key: "book",
          title: "کتاب‌ها"
        },
        {
          key: "collection",
          title: "مجموعه‌ها"
        }
      ],
      year: new Date().getFullYear(),
      editChartFilter: false,
      series: [{
        name: "views",
        data: []
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [],
        }
      },
      headers: [
        {
          text: 'تیتر:',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {
          text: 'بازدید :',
          align: 'start',
          sortable: false,
          value: 'visitor_count',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
      lastContent: null,
      popularArticle:null,
    }
  },
  methods: {
    getOverviewData() {
      this.requesting('dashboard', 'getOverview').then((resp) => {
        this.overview = resp.data.data
      })
    },
    getDataForChart() {
      this.editChartFilter = false;
      this.series[0].data = []
      this.requesting('dashboard', 'getChartData', {}, {
        year: this.year,
        type: this.type
      }).then((resp) => {
        resp.data.chart.forEach(data => {
          // this.values.push(data.count)
          this.series[0].data.push(data.count)
          this.chartOptions.xaxis.categories.push(data.title)
          this.labels.push(data.title)
        })
        console.log(this.series)
      })
    },
    getLastContent() {
      this.requesting('content', 'getLastContent', {
        limit: 6
      }).then((resp) => {
        this.lastContent = resp.data.contents
      })
    } ,
    getPopularContent() {
      this.requesting('content', 'getPopularContent', {
        limit: 6
      }).then((resp) => {
        this.popularArticle = resp.data.contents
      })
    }
  },
  mounted() {
    this.getOverviewData()
    this.getDataForChart()
    this.getLastContent()
    this.getPopularContent()
  }
}
</script>

<style scoped>
.labelNumber {
  font-size: 50pt;
  line-height: 100pt;
}

.labelStatistics {
  font-size: 20px;
}
</style>
import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL

/*eslint-disable*/
export function ajaxGet(path, payload, header, notif) {
    if (path) {
        return new Promise((resolve, reject) => {
            axios.get(baseUrl + path, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')} `,
                    // header
                },
            }).then((resp) => {
                return resolve(resp.data)
            }).catch((er) => {
                if (er.response.statusCode == 401) {
                    // this.$router.push({name:"login"})
                    localStorage.removeItem('token')
                    window.location.replace('/login')
                }
                return reject(er)
            })
        })
    }
}

export function ajaxPost(path, payload, header, notif) {
    if (path) {

        return new Promise((resolve, reject) => {
            axios.post(baseUrl + path, payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')} `,
                    // header
                },
            }).then((resp) => {
                return resolve(resp.data)
            }).catch((er) => {
                if (er.response.status == 401) {
                    // this.$router.push({name:"login"})
                    localStorage.removeItem('token')
                    window.location.replace('/login')
                }
                return reject(er)
            })
        })
    }
}

let httpUtilities = {
    get: ajaxGet,
    post: ajaxPost
}
export default httpUtilities
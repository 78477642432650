const baseUrl = 'main/'
/*eslint-disable*/
export default [
    {
        name: "login",
        path: baseUrl + "auth/login",
        method: "post",
        loading: true,
        alert: true,
        commits: [
            {
                name: 'SET_TOKEN',
                key: 'token',
                dataPath: 'data.token',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
        ],
        defaultHeader: {},
        defaultBody: {
            login_type:'password'
        },
    },
]
<template>
  <div>
    <v-text-field dense style="width: 500px;" v-model="content['time_read']" outlined label="زمان برای مطالعه"></v-text-field>
    <TextEditor :content="content"/>
  </div>
</template>

<script>
/*eslint-disable*/
import VueFroala from 'vue-froala-wysiwyg';
import TextEditor from "@/components/main/TextEditor";

export default {
  components: {TextEditor},
  props:{
    content:Object,
  },
}
</script>
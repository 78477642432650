<template>
  <div class="">
    <v-navigation-drawer v-model="sideBar" app right color="#1a1d24" width="300">
      <div class=" mx-2 my-5">
        <div class="text-center">
          <router-link to="/dashboard">
            <img src="../assets/logo_site.png" width="100px" alt="">
          </router-link>
        </div>
        <div class="menuItem">
          <v-list class="">
            <v-list-item v-for="(item , i ) in menuItem" :key="i">
              <v-list-group style="width: 100%" :prepend-icon="item.icon">
                <template v-slot:activator class="">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>
                <v-list-item v-for="(child , i) in item.children" :key="i">
                  <v-list-item-icon>
                    <v-icon>
                      {{ child.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <router-link :to="child.route">
                    {{ child.title }}
                  </router-link>
                </v-list-item>
              </v-list-group>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>
    <div class=" mx-10 pt-10">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sideBar: true,
      menuItem: [
        {
          title: "مدیریت محتوا",
          icon: "mdi-file",
          children: [
            {
              icon: "mdi-list-status",
              title: "همه محتوا‌ها",
              route: {name: 'contentList'}
            },
            {
              icon: "mdi-book-multiple",
              title: "کتاب‌ها",
              route: {name: 'contentList', query: {type: 'book'}}
            },
            {
              icon: "mdi-file",
              title: "مقالات",
              route: {name: 'contentList', query: {type: 'article'}}
            },
            {
              icon: "mdi-collage",
              title: "مجموعه‌ها",
              route: {name: 'contentList', query: {type: 'collection'}}
            },
            {
              icon: "mdi-plus",
              title: "افزودن",
              route: {name: 'addContent'}
            }
          ]
        },
        {
          title: "دسته‌بندی‌ها",
          icon: "mdi-file",
          children: [
            {
              title: "لیست",
              route: {name: 'categoryList'},
              icon: "mdi-list-status",
            },
            {
              title: "افزودن",
              route: {name: 'addCategory'},
              icon: "mdi-plus",
            }
          ]
        },
        {
          title: "خبر‌نامه",
          icon: "mdi-file",
          children: [
            {
              title: "لیست",
              route: {name: 'newsletterList'},
              icon: "mdi-list-status",
            },
            {
              title: "افزودن",
              route: {name: 'addNewsletter'},
              icon: "mdi-plus",
            }
          ]
        },

        {
          title: "کاربران",
          icon: "mdi-account",
          children: [
            {
              title: "همه کاربران",
              route: {name: 'userList'},
              icon: "mdi-list-status",
            },
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
a {
  color: inherit !important;
  text-decoration: none;
}
</style>
import articles from "./list/articleRequests";
import auth from './list/auth'
import dashboard from './list/dashboardRequest'
import content from './list/contentRequests'
import category from './list/categoryRequests'
import users  from './list/usersRequests'
import newsletter  from './list/newslettersRequests'
export default {
    articles,
    auth,
    dashboard,
    content,
    category,
    users,
    newsletter
}
const baseUrl = 'admin/dashboard/'
/*eslint-disable*/
export default [
    {
        name: "getOverview",
        path: baseUrl + "overview",
        method: "get",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody:{}
    },
    {
        name: "getChartData",
        path: baseUrl + "chart/data",
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody:{}
    },
]
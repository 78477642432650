/*eslint-disable*/

let filed = {
    titleFilter: {
        key: 'title',
        label: 'تیتر',
        nullable: true,
        type: 'text'
    },
    nameFilter: {
        key: 'name',
        label: 'نام و نام خانواگی',
        nullable: true,
        type: 'text'
    },
    emailFilter: {
        key: 'email',
        label: 'ایمیل',
        nullable: true,
        type: 'text'
    },
    sortByContent: {
        key: "orderBy",
        label: "نمایش بر اساس",
        type: "singleSelect",
        nullable: true,
        items: [
            {
                title: "کد",
                key: "id",
            },
            {
                title: "تاریخ ایجاد",
                key: "created_at",
            },
            {
                title: "تاریخ آخرین ویرایش",
                key: "updated_at",
            }
        ],
    },
    sortByTypeContent: {
        key: "order_type",
        label: "نمایش به صورت ",
        type: "singleSelect",
        nullable: true,
        items: [
            {
                title: "صعودی",
                key: "ASC",
            },
            {
                title: "نزولی",
                key: "DESC",
            },
        ],
    },
    RoleUser: {
        key: "role",
        label: "نقش",
        type: "singleSelect",
        nullable: true,
        items: [
            {
                title: "کاربر",
                key: "user",
            },
            {
                title: "ادمین",
                key: "admin",
            },
        ],
    },
    typeContentFilter: {
        key: "type",
        label: "نوع محتوا",
        type: "singleSelect",
        nullable: true,
        items: [
            {
                title: "مقالات",
                key: "article",
            },
            {
                title: "کتاب‌ها",
                key: "book",
            },
            {
                title: "مجموعه‌ها",
                key: "collection",
            },
        ],
    },
    createdAtStartFilter: {
        key: 'start_at',
        label: 'ایجاد شده از',
        type: 'dateTime'
    },
    createdAtEndFilter: {
        key: 'end_at',
        label: 'ایجاد شده تا',
        type: 'dateTime'
    }
}

export const contentFilters = [filed.titleFilter, filed.sortByContent, filed.sortByTypeContent, filed.typeContentFilter, filed.createdAtStartFilter, filed.createdAtEndFilter];
export const categoryFilters = [filed.titleFilter,filed.sortByContent,filed.sortByTypeContent,filed.createdAtStartFilter,filed.createdAtEndFilter]
export const usersFilters = [filed.nameFilter,filed.emailFilter,filed.RoleUser]
<template>
  <div>
    <DeleteDialog :delete-modal="dialogDelete" :delete-i-d="deleteID" @delete="deleteContent"
                  @cancel="dialogDelete = false"/>
    <div class="boxAdmin pa-4">
      <h2>
        همه دسته‌بندی‌ها
      </h2>
      <TableOption :filters="filters" @usingFilter="getCategoryList"/>
      <v-data-table
          v-if="categories"
          :headers="headers"
          :items="categories"
          class="blue"
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item.action="{ item }">
          <v-btn elevation="0" fab x-small :to="`/dashboard/category/${item.id}`">
            <v-icon size="small">mdi-pencil</v-icon>
          </v-btn>

          <v-btn elevation="0" fab x-small :color="item.show_in_index && 'primary'"
                 @click="changeShowInIndex(!item.show_in_index , item.id)">
            <v-icon size="small">{{ item.show_in_index ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
          </v-btn>

          <v-btn elevation="0" fab x-small color="warning" @click="openDialogDelete(item.id)">
            <v-icon size="small">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
<!--      <v-pagination class="mt-10" :length="pagination.total" v-model="pagination.page"></v-pagination>-->
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {categoryFilters} from "../../../../filters";
import TableOption from "@/components/tableOption";
import {EventBus} from "@/event-bus";
import DeleteDialog from "@/components/main/DeleteDialog";


export default {
  components: {DeleteDialog, TableOption},
  data() {
    return {
      headers: [
        {
          text: 'کد :',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'نام:',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {
          text: 'کلید:',
          align: 'start',
          sortable: false,
          value: 'slug',
        },
        {
          text: 'تعداد محتوا :',
          align: 'start',
          sortable: false,
          value: 'count_contents',
        },
        {
          text: 'تاریخ ایجاد :',
          align: 'start',
          sortable: false,
          value: 'publishDate',
        },
        {
          text: 'آخرین ویرایش :',
          align: 'start',
          sortable: false,
          value: 'lastEditDate',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
      categories: null,
      dialogDelete: false,
      deleteID: null,
      pagination: {
        total: 0,
        page: 1,
      },
    }
  },
  methods: {
    getCategoryList() {
      EventBus.$emit('setLoading', true)
      let query = {
        page: this.pagination.page,
        pagination: 20
      }
      this.requesting('category', 'getAllCategories', query, this.$route.query).then((resp) => {
        this.categories = resp.data.categories
        // this.pagination.total = resp.data.pagination.total_pages
        // if (this.pagination.page > resp.data.pagination.total_pages) {
        //   this.pagination.page = 1
        //   this.getListContent();
        // }
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    changeShowInIndex(status, id) {
      EventBus.$emit('setLoading', true)
      this.requesting('category', 'updateCategory', {
        id: id
      }, {
        show_in_index: status
      }).then(() => {
        this.getCategoryList()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    openDialogDelete(id) {
      this.dialogDelete = true
      this.deleteID = id
    },
    deleteContent(id) {
      EventBus.$emit('setLoading', true)
      this.requesting('category', 'deleteCategory', {id: id}).then(() => {
        EventBus.$emit('notify', {color: 'green', text: 'حذف با موفقیت انجام شد', type: "success"})
        this.getCategoryList();
        this.dialogDelete = false
        this.deleteID = null
      }).finally(() => {
        EventBus.$emit('setLoading', true)

      })
    }
  },
  mounted() {
    this.getCategoryList()
  },
  computed: {
    filters() {
      return categoryFilters;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div class="boxAdmin pa-4" v-if="newsletter">
      <h2>
        {{ newsletter.title }}
      </h2>
      <p style="line-height: 30pt">
        {{ newsletter.description }}
      </p>
      <div v-if="newsletter.file">
        <a :href="newsletter.file.url" target="_blank">
          دانلود فایل
        </a>
      </div>
      <div class="mt-5">
        <b class="ml-2">
          تعداد ارسال:
        </b>
        {{ newsletter.send_count }}
      </div>
      <div class="mt-5">
        <b class="ml-2">
          تعداد ایمیل‌های باز شده:
        </b>
        {{ opened_email }}
      </div>
      <v-data-table
          v-if="newsletter.logs"
          :headers="headers"
          :items="newsletter.logs"
          class="blue"
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item.created_at="{item}">
          {{ item.created_at | filteringTime }}
        </template>
        <template v-slot:item.state="{item}">
          <template v-if="item.state == 'sent'">
            <div class="sentState">
              ارسال شده
            </div>
          </template>
          <template v-if="item.state == 'fail'">
            <div class="didntOpened">
              اشکال در ارسال
            </div>
          </template>
          <template v-if="item.state == 'pending'">
            <div class="pendingState">
              در حال ارسال
            </div>
          </template>
        </template>
        <template v-slot:item.updated_at="{item}">
          <template v-if="item.is_seen">
            {{ item.updated_at | filteringTimeWithTime }}
          </template>
          <template v-else>
            <div class="didntOpened">
              ایمیل باز نشده
            </div>
          </template>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
/*eslint-disable*/
export default {
  data() {
    return {
      newsletter: null,
      headers: [
        {
          text: 'کد ',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'ایمیل',
          align: 'start',
          sortable: false,
          value: 'email',
        },
        {
          text: 'وشعیت',
          align: 'start',
          sortable: false,
          value: 'state',
        },
        {
          text: 'تاریخ ارسال',
          align: 'start',
          sortable: false,
          value: 'created_at',
        },
        {
          text: 'تاریخ بازکردن ایمیل',
          align: 'start',
          sortable: false,
          value: 'updated_at',
        },
      ],
      opened_email: 0,

    }
  },
  methods: {
    getNewsletter() {
      EventBus.$emit('setLoading', true)
      this.requesting('newsletter', 'getNewsletter', {id: this.$route.params.id},).then((resp) => {
        this.newsletter = resp.data.newsletter
        this.newsletter.logs.forEach((log) => {
          if (log.is_seen) {
            this.opened_email++;
          }
        })
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
  },
  mounted() {
    this.getNewsletter()
  }
}
</script>

<style scoped>
.didntOpened {
  background: red;
  width: 100px;
  color: white;
  text-align: center;
  font-size: 12px;
  border-radius: 10px;
  font-weight: bolder;
}

.sentState {
  background: green;
  width: 100px;
  color: white;
  text-align: center;
  font-size: 12px;
  border-radius: 10px;
  font-weight: bolder;
}

.pendingState {
  background: cornflowerblue;
  width: 100px;
  color: white;
  text-align: center;
  font-size: 12px;
  border-radius: 10px;
  font-weight: bolder;
}
</style>
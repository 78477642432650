<template>
  <div>
    <div class="boxAdmin pa-5">
      <h2 class="py-3">افزودن محتوا</h2>
      <v-divider></v-divider>
      <v-form ref="addContentFont">
        <v-row>
          <v-col cols="12" lg="12">
            <div class="contentArea pt-10">
              <v-text-field
                  outlined
                  dense
                  label="تیتر"
                  v-model="content['title']"
                  :rules="[rules.required]"
              ></v-text-field>
              <v-textarea :rules="[rules.required]" v-model="content['description']" label="توضیح کوتاه " outlined
                          dense></v-textarea>
              <v-autocomplete
                  v-if="categories"
                  v-model="content['categories']"
                  :items="categories"
                  outlined
                  dense
                  chips
                  small-chips
                  label="دسته‌بندی‌ها"
                  multiple
                  item-text="title"
                  item-value="id"
                  :rules="[rules.required]"
              ></v-autocomplete>
              <v-text-field
                  v-model="content['slug']"
                  outlined
                  dense
                  label="آدرس پیشنهادی"
                  dir="ltr"
              ></v-text-field>
              <v-select label="نویسنده" v-model="content['user_id']" :items="admins" item-value="id" item-text="name" dense outlined v-if="admins">

              </v-select>
              <v-file-input :rules="[rules.required]" v-model="content['primary_picture']" outlined label="عکس شاخض"
                            dense></v-file-input>
              <v-select :rules="[rules.required]" :items="types" dense v-model="type" label="نوع محتوا" outlined
                        item-value="key"
                        item-text="title"></v-select>
              <v-checkbox v-model="content['need_vpn']" :value="true" label="نیاز به فیلترشکن"></v-checkbox>
            </div>
            <v-fade-transition mode="out-in">
              <component :is="contentComponent" :content="content"></component>
            </v-fade-transition>
            <!--          <AddArticle :content="content" />-->
            <!--          <AddBook :content="content"/>-->
            <!--          <AddCollection :content="content"/>-->
            <v-btn class="mt-10" color="primary" @click="storeContent">
              ایجاد مقاله
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import AddArticle from "@/components/Content/addForm/AddArticle";
import AddBook from "@/components/Content/addForm/AddBook";
import AddCollection from "@/components/Content/addForm/AddCollection";
import {EventBus} from "@/event-bus";

export default {
  components: {AddCollection, AddBook, AddArticle},
  data() {
    return {
      content: {},
      categories: null,
      img: null,
      imageUrl: null,
      types: [
        {
          title: "کتاب",
          key: "book"
        },
        {
          title: "مقالات",
          key: "article"
        },
        {
          title: "مجموعه",
          key: "collection"
        }
      ],
      type: null,
      contentComponent: null,
      admins:null,
    }
  },
  methods: {
    getCategories() {
      this.requesting('category', 'getAllCategoriesFroContent').then((resp) => {
        this.categories = resp.data.categories
      })
    },
    getAdmins() {
      this.requesting('users', 'getAdmins').then((resp) => {
        this.admins = resp.data.users

      })
    },
    storeContent() {
      if (this.$refs.addContentFont.validate()) {
        let payload = new FormData();
        Object.keys(this.content).forEach(item => {
          payload.append(item, this.content[item])
        })
        EventBus.$emit('setLoading', true)
        this.requesting('content', 'insertContent', {
          type: this.type,
        }, payload, {}).then(resp => {
          EventBus.$emit('notify', {color: 'green', text: 'محتوا اضافه شد', type: "successs"})
          this.$router.push({name: "contentList", query: {type: this.type, orderBy: 'id', order_type: 'DESC'}})
        }).catch(() => {
          EventBus.$emit('notify', {color: 'red', text: 'فرایند با مشکل مواجه شد', type: "error"})
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    }
  },
  mounted() {
    this.getCategories()
    this.getAdmins()
  },
  watch: {
    img(val) {
      this.imageUrl = URL.createObjectURL(val)
    },
    type(val) {
      switch (val) {
        case "book":
          this.contentComponent = "AddBook"
          return;
        case "article":
          this.contentComponent = "AddArticle"
          return;
        case "collection":
          this.contentComponent = "AddCollection"
          return;
      }
    }
  }
}
</script>

<style>
.contentArea {
  width: 500px;
}
</style>
<template>
  <div>
    <DeleteDialog :delete-modal="dialogDelete" :delete-i-d="deleteID" @delete="deleteContent"
                  @cancel="dialogDelete = false"/>
    <div class="boxAdmin pa-4">
      <h2>
        همه کاربران
      </h2>
      <TableOption :filters="filters" @usingFilter="getUsers"/>
      <v-data-table
          v-if="users"
          :headers="headers"
          :items="users"
          class="blue"
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item.register_at="{ item }">
          {{ dateFilter(item.register_at) }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small :disabled="item.email_verification_at == null">
                {{ item.email_verification_at == null ? 'mdi-email-outline' : 'mdi-email' }}
              </v-icon>
            </template>
            <span>این کاربر ایمیل خود را تایید کرده است</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="mx-1" small :disabled="item.newsletter == null">
                {{ item.newsletter == 0 ? 'mdi-email-newsletter-outline' : 'mdi-email-newsletter' }}
              </v-icon>
            </template>
            <span>این کاربر در خبرنامه عضو شده است</span>
          </v-tooltip>

        </template>
      </v-data-table>
      <!--      <v-pagination class="mt-10" :length="pagination.total" v-model="pagination.page"></v-pagination>-->
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {categoryFilters, usersFilters} from "../../../../filters";
import TableOption from "@/components/tableOption";
import {EventBus} from "@/event-bus";
import DeleteDialog from "@/components/main/DeleteDialog";
import moment from "jalali-moment";

export default {
  components: {DeleteDialog, TableOption},
  data() {
    return {
      headers: [
        {
          text: 'کد :',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'نام:',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'ایمیل :',
          align: 'start',
          sortable: false,
          value: 'email',
        },
        {
          text: 'وضعیت :',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: 'شماره تماس :',
          align: 'start',
          sortable: false,
          value: 'tell',
        },
        {
          text: 'تاریخ ثبت‌نام :',
          align: 'start',
          sortable: false,
          value: 'register_at',
        },

        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
      users: null,
      dialogDelete: false,
      deleteID: null,
      pagination: {
        total: 0,
        page: 1,
      },
    }
  },
  methods: {
    getUsers() {
      EventBus.$emit('setLoading', true)
      let query = {
        page: this.pagination.page,
        pagination: 20
      }
      this.requesting('users', 'getUsers', query, this.$route.query).then((resp) => {
        this.users = resp.data.users
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    dateFilter(date) {
      return moment(date).format('jYYYY/jMM/jDD')
    }

  },
  mounted() {
    this.getUsers()
  },
  computed: {
    filters() {
      return usersFilters;
    },

  }
}
</script>

<style scoped>

</style>
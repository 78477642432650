<template>
  <div>
    <div class="boxAdmin pa-4">
      <h2>
        ایجاد دسته‌بندی
      </h2>
      <div class="contentArea mt-10">
        <v-form ref="addCategory">
          <v-text-field :rules="[rules.required]" v-model="category['name']" outlined dense
                        label="کلید :"></v-text-field>
          <v-text-field :rules="[rules.required]" v-model="category['title']" outlined dense
                        label="نام :"></v-text-field>
          <v-text-field v-model="category['color']" outlined dense type="color" label="رنگ :"></v-text-field>
          <v-file-input v-model="category['primaryPicture']" label="عکس شاخص" outlined dense></v-file-input>
          <img :src="category.primary_picture" v-if="category.primary_picture" width="200px" alt="">
          <v-checkbox v-model="category['show_in_index']" :value="1" label="نمایش در صفحه اصلی"></v-checkbox>
          <v-btn color="primary" @click="editCategory">افزودن دسته‌بندی</v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      category: {},
    }
  },
  methods: {
    getCategory() {
      EventBus.$emit('setLoading', true)
      this.requesting('category', 'getCategory', {
        id: this.$route.params.id
      }).then(resp => {
        console.log(resp.data.category,'212121212')
        this.category = resp.data.category
        this.category.name = resp.data.category.slug
        // this.category.primaryPicture = resp.data.primary_picture.path
      }).finally(()=>{
        EventBus.$emit('setLoading', false)
      })
    },
    editCategory() {
      EventBus.$emit('setLoading', true)
      delete this.category.primary_picture
      let payload = new FormData();
      console.log(this.category.primaryPicture)
      if (this.category.primaryPicture) {
        this.category.primary_picture = this.category.primaryPicture
      }
      Object.keys(this.category).forEach(item => {
        payload.append(item, this.category[item])
      })
      this.requesting('category', 'updateCategory', {id: this.$route.params.id}, payload).then(() => {
        EventBus.$emit('notify', {color: 'green', text: 'دسته‌بندی اضافه شد', type: "success"})
        this.getCategory()
      }).catch(() => {
        EventBus.$emit('notify', {color: 'red', text: 'فرایند با مشکل مواجه شد', type: "error"})
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {
    this.getCategory()
  }
}
</script>

<style scoped>
.contentArea {
  width: 500px;
}
</style>
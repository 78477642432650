<template>
  <div>
    <DeleteDialog :delete-modal="dialogDelete" :delete-i-d="deleteID" @delete="deleteContent"
                  @cancel="dialogDelete = false"/>
    <div class="boxAdmin pa-4 text-center">
      <h2>
        همه محتوا‌ها
      </h2>
      <TableOption :filters="filters" @usingFilter="getListContent($event)"/>
      <v-data-table
          :headers="headers"
          :items="contents"
          class="blue"
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item.action="{ item }">
          <v-btn elevation="0" fab x-small :to="`/dashboard/content/${item.id}`">
            <v-icon size="small">mdi-pencil</v-icon>
          </v-btn>

          <v-btn elevation="0" fab x-small :color="!item.status && 'primary'"
                 @click="changeStatus(!item.status , item.id)">
            <v-icon size="small">{{ item.status ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
          </v-btn>

          <v-btn elevation="0" fab x-small color="warning" @click="openDialogDelete(item.id)">
            <v-icon size="small">mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.type="{item}">
          {{ item.type | typeFilter }}
          {{ item.type | emojiFilter }}
        </template>
        <template v-slot:item.publishDate="{item}">
          {{ item.publishDate | filteringTime }}
        </template>
        <template v-slot:item.lastEditDate="{item}">
          {{ item.lastEditDate | filteringTime }}
        </template>
      </v-data-table>
      <v-pagination class="mt-10" :length="pagination.total" v-model="pagination.page"
                    @="getListContent"></v-pagination>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import TableOption from "@/components/tableOption";
import {contentFilters} from "../../../../filters";
import DeleteDialog from "@/components/main/DeleteDialog";

export default {
  components: {DeleteDialog, TableOption},
  data() {
    return {
      headers: [
        {
          text: 'کد :',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'تیتر:',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {
          text: 'نوع محتوا:',
          align: 'start',
          sortable: false,
          value: 'type',
        },
        {
          text: 'بازدید :',
          align: 'start',
          sortable: false,
          value: 'visitor_count',
        },
        {
          text: 'تاریخ ایجاد :',
          align: 'start',
          sortable: false,
          value: 'publishDate',
        },
        {
          text: 'آخرین ویرایش :',
          align: 'start',
          sortable: false,
          value: 'lastEditDate',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
      contents: [],
      dialogDelete: false,
      deleteID: null,
      pagination: {
        total: 0,
        page: 1,
      },
    }
  },
  methods: {
    changeStatus(status, id) {
      EventBus.$emit('setLoading', true)
      this.requesting('content', 'changeStatusContent', {
        id: id
      }, {
        status: status,
      }).then(() => {
        this.getListContent()
      }).finally(() => {
        EventBus.$emit('setLoading', true)
      })
    },
    getListContent() {
      EventBus.$emit('setLoading', true)
      let query = {
        page: this.pagination.page,
        type: '',
        pagination: 20
      }
      if (this.$route.query.type) {
        query.type = this.$route.query.type
      }
      this.requesting('content', 'getAllContents', query, this.$route.query).then((resp) => {
        window.scrollTo({top: 0})
        this.contents = resp.data.contents
        this.pagination.total = resp.data.pagination.total_pages
        if (this.pagination.page > resp.data.pagination.total_pages) {
          this.pagination.page = 1
          this.getListContent();
        }
      }).finally(() => {
        setTimeout(() => {
          EventBus.$emit('setLoading', false)
        }, 500)
      })
    },
    openDialogDelete(id) {
      this.dialogDelete = true
      this.deleteID = id
    },
    deleteContent(id) {
      EventBus.$emit('setLoading', true)
      this.requesting('content', 'deleteStatusContent', {
        id: id,
      }).then(() => {
        this.dialogDelete = false
        EventBus.$emit('notify', {color: 'green', text: 'حذف با موفقیت انجام شد', type: "success"})
        this.getListContent()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {
    this.getListContent()
  },
  watch: {
    'pagination.page'() {
      this.getListContent()
    },
    '$route'() {
      this.getListContent()
    }
  },
  computed: {
    filters() {
      return contentFilters;
    }
  },
  filters: {}
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div class="boxAdmin pa-5">
      <h2 class="py-3">ویرایش محتوا</h2>
      <v-divider></v-divider>
      <v-form ref="addContentFont" v-if="content">
        <v-row>
          <v-col cols="12" lg="12">
            <div class="contentArea pt-10">
              <v-text-field
                  outlined
                  dense
                  label="تیتر"
                  v-model="content['title']"
                  :rules="[rules.required]"
              ></v-text-field>
              <v-textarea :rules="[rules.required]" v-model="content['description']" label="توضیح کوتاه " outlined
                          dense></v-textarea>
              <v-autocomplete
                  v-if="categories"
                  v-model="content['categories']"
                  :items="categories"
                  outlined
                  dense
                  chips
                  small-chips
                  label="دسته‌بندی‌ها"
                  multiple
                  item-text="title"
                  item-value="id"
                  :rules="[rules.required]"
              ></v-autocomplete>
              <v-text-field
                  v-model="content['slug']"
                  outlined
                  dense
                  label="آدرس پیشنهادی"
                  dir="ltr"
              ></v-text-field>
              <v-file-input v-model="content['primaryPicture']" outlined label="عکس شاخض"
                            dense></v-file-input>
              <img :src="content.primary_picture" v-if="content.primary_picture" width="200xp">
              <v-checkbox v-model="content['need_vpn']" :value="true" label="نیاز به فیلترشکن"></v-checkbox>
            </div>
            <v-fade-transition mode="out-in">
              <component :is="contentComponent" :content="content"></component>
            </v-fade-transition>
            <v-btn class="mt-10" color="primary" @click="editContent">
              ویرایش مقاله
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import AddArticle from "@/components/Content/addForm/AddArticle";

import AddCollection from "@/components/Content/addForm/AddCollection";
import {EventBus} from "@/event-bus";
import EditBook from "@/components/Content/addForm/editBook";

export default {
  components: {EditBook, AddCollection, AddArticle},
  data() {
    return {
      content: {},
      categories: null,
      img: null,
      imageUrl: null,
      types: [
        {
          title: "کتاب",
          key: "book"
        },
        {
          title: "مقالات",
          key: "article"
        },
        {
          title: "مجموعه",
          key: "collection"
        }
      ],
      type: null,
      contentComponent: null,
    }
  },
  methods: {
    getCategories() {
      this.requesting('category', 'getAllCategoriesFroContent').then((resp) => {
        this.categories = resp.data.categories
      })
    },
    getContent() {
      EventBus.$emit('setLoading', true)
      this.requesting('content', 'getContent', {
        id: this.$route.params.id
      }).then((resp) => {
        this.content = {
          title: resp.data.content.title,
          description: resp.data.content.description,
          slug: resp.data.content.slug,
          primary_picture: resp.data.content.primary_picture.path,
          categories: [...resp.data.content.categories],
          body: resp.data.content.content.body,
          need_vpn: resp.data.content.need_vpn
        }
        if (resp.data.content.content.hasOwnProperty('contents')) {
          this.content.contents = resp.data.content.content.contents
        }
        if (resp.data.content.type == 'book') {
          this.content['type'] = resp.data.content.content.type
          this.content['path'] = resp.data.content.content.path
          this.content['author_info'] = resp.data.content.content.author_info
          this.content['page_count'] = resp.data.content.content.page_count
          if (resp.data.content.content.type == 'internal') {
            this.content['file'] = resp.data.content.content.file.path
          }
          this.$store.commit('setContent', this.content)
        }

        this.type = resp.data.content.type

      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    editContent() {
      let payload = new FormData();
      switch (this.content.need_vpn) {
        case true:
          this.content.need_vpn = 1
          break;
        case false:
          this.content.need_vpn = 0
          break;
        default:
          this.content.need_vpn = 0
          break;
      }
      delete this.content.primary_picture
      // delete this.content.need_vpn
      delete this.content.content
      if (this.content.categories) {
        if (this.content.categories.length != 0) {
          if (this.content.categories[0].hasOwnProperty('id')) {
            console.log('bsdbsf')
            let ids = []
            this.content.categories.forEach((item) => {
              ids.push(item.id)
            })
            this.content.categories = ids
          }
        } else {
          this.content.categories = null
        }
      }
      if (this.type == 'collection') {
        if (this.content.contents) {
          if (this.content.contents.length != 0) {
            if (this.content.contents[0].hasOwnProperty('id')) {
              let ids = []
              this.content.contents.forEach((item) => {
                ids.push(item.id)
              })
              this.content.contents = ids
            }
          }
        }
      }

      console.log(this.content)
      if (this.content.primaryPicture) {
        this.content.primary_picture = this.content.primaryPicture
      }
      Object.keys(this.content).forEach(item => {
        payload.append(item, this.content[item])
      })

      EventBus.$emit('setLoading', true)
      this.requesting('content', 'updateContent', {
        id: this.$route.params.id
      }, payload, {}).then(resp => {
        EventBus.$emit('notify', {color: 'green', text: 'محتوا ویرایش شد', type: "successs"})
        this.getContent();
      }).catch(() => {
        EventBus.$emit('notify', {color: 'red', text: 'فرایند با مشکل مواجه شد', type: "error"})
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {
    this.getCategories()
    this.getContent()
  },
  watch: {
    type(val) {
      switch (val) {
        case "book":
          this.contentComponent = "EditBook"
          return;
        case "article":
          this.contentComponent = "AddArticle"
          return;
        case "collection":
          this.contentComponent = "AddCollection"
          return;
      }
    },
    content() {

    }
  }
}
</script>

<style>
.contentArea {
  width: 500px;
}
</style>
<template>
  <div class="loginPage fill-height">
    <v-card class="loginCard" width="500px">
      <v-card-title class="text-center justify-center">
        <img src="../../assets/logo_site.png" width="250px" alt="">
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form class="mx-5 my-5" ref="loginForm">
          <v-text-field dir="ltr" :rules="[rules.emailRules,rules.required]" v-model="email" label="ایمیل : " outlined dense
                        append-icon="mdi-email" color="primary"/>
          <v-text-field
              dir="ltr"
              v-model="password"
              dense
              outlined
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label=" رمز عبور : "
              @click:append="show1 = !show1"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center mb-4">
        <v-btn color="primary" @click="login">ورود</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      show1: false,
      password: "",
      email: "",
    }
  },
  methods: {
    login() {
      if (this.$refs.loginForm.validate()) {
        EventBus.$emit('setLoading', true)
        this.requesting('auth', 'login', {}, {
          email: this.email,
          password: this.password,
          login_type:'password'
        }).then(() => {
          EventBus.$emit('notify', {color: 'green', text: 'خوش‌آمدید', type: "wellcome"})
          setTimeout(() => {
            this.$router.push({name: "dashboard"})
          }, 500)
        }).catch(er => {
          if (er.response.status == 401) {
            EventBus.$emit('notify', {color: 'red', text: 'نام‌کاربری یا رمز عبور اشتباه است', type: "error"})
          }
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.loginPage {
  background: url("../../assets/patternLogin.png");
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginCard {
  border-radius: 10px !important;
}
</style>
<template>
  <div>
    <v-expansion-panels class="elevation-0 mb-5 mt-5">
      <v-expansion-panel class="elevation-0">
        <v-expansion-panel-header class="elevation-0">
          فیلتر‌ها
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form @keydown.native.enter="usingFilters">
            <v-row class="mx-3">
              <v-col cols="12" lg="3" v-for="(filter , i) in filters" :key="i">
                <template v-if="filter.type == 'text'">
                  <v-text-field :label="filter.label" dense outlined v-model="outputFilters[filter.key]"/>
                </template>
                <template v-else-if="filter.type == 'singleSelect'">
                  <v-select outlined dense :label="filter.label" :items="filter.items" item-text="title"
                            item-value="key"
                            v-model="outputFilters[filter.key]"></v-select>
                </template>
                <template v-else-if="filter.type == 'dateTime'">
<!--                  <date-picker  ></date-picker>-->
                  {{filter.label}}
                  <date-picker v-model="outputFilters[filter.key]" :column="1" mode="single"></date-picker>
                </template>
              </v-col>
            </v-row>
          </v-form>
          <div class="justify-end d-flex">
            <v-btn color="primary ml-1" @click="usingFilters">
              اعمال فیلتر
            </v-btn>
            <v-btn color="primary" outlined @click="resetFilter">
              لغو فیلتر‌ها
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
/*eslint-disable*/
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

export default {
  components: {VuePersianDatetimePicker},
  props: {
    filters: Array,
  },
  data() {
    return {
      outputFilters: [],
    }
  },
  mounted() {
    this.outputFilters = this.$route.query
  },
  methods: {
    usingFilters() {
      let RouteName = this.$route.name
      let RouteInfo = {}
      RouteInfo.query = {}
      Object.keys(this.outputFilters).forEach((item) => {
        RouteInfo.query[item] = this.outputFilters[item]
      })

      window.history.pushState("", "", this.prepareUrl(RouteInfo.query));
      // this.$router.push({name: RouteName})
      // this.$router.push({query: RouteInfo.query})
      this.$emit('usingFilter', RouteInfo.query)
    },
    prepareUrl(query) {
      let route = this.$route.path
      if (Object.keys(query).length > 0) {
        route += '?'
        Object.keys(query).forEach(item => {
          if (query[item]) {
            route += `${item}=${query[item]}&`
          }
        })
      }
      return route

    },
    resetFilter() {
      this.outputFilters = [];
      window.history.pushState("", "", this.$route.path);
      this.$emit('usingFilter')
    }
  },
  watch: {
    '$route'() {
      this.outputFilters = this.$route.query
    }
  }
}
</script>

<style scoped>

</style>
import MainLayout from "@/layouts/mainLayout";
import DashboardLayout from "@/layouts/dashboardLayout";
import Login from "@/views/auth/login";
import indexDashboard from '@/views/dashboard/index'
import ContentsListView from "@/views/dashboard/Contents/ContentsListView";
import AddContent from "@/views/dashboard/Contents/addContent";
import EditContent from "@/views/dashboard/Contents/editContent";
import CategoryListView from "@/views/dashboard/category/CategoryListView";
import AddCategory from "@/views/dashboard/category/addCategory";
import EditCategory from "@/views/dashboard/category/editCategory";
import UsersList from "@/views/dashboard/users/usersList";
import newsletterList from "@/views/dashboard/newsletter/newsletterList";
import StatisticsNewsletter from "@/views/dashboard/newsletter/statisticsNewsletter";
import EditNewsletter from "@/views/dashboard/newsletter/EditNewsletter";
import addNewsletter from "@/views/dashboard/newsletter/AddNewsletter";

const routes = [
    {
        path: "/",
        component: MainLayout,
        children: [
            {
                path: "dashboard",
                component: DashboardLayout,

                meta: {
                    role: "admin"
                },
                children: [
                    {
                        path: "",
                        component: indexDashboard,
                        name: "dashboard",
                    },
                    {
                        path: "content",
                        component: ContentsListView,
                        name: "contentList"
                    },
                    {
                        path: "content/add",
                        component: AddContent,
                        name: "addContent"
                    },
                    {
                        path: "content/:id",
                        component: EditContent,
                        name: "editContent"
                    },
                    {
                        path: "category",
                        component: CategoryListView,
                        name: "categoryList"
                    },
                    {
                        path: "category/add",
                        component: AddCategory,
                        name: "addCategory"
                    },
                    {
                        path: "category/:id",
                        component: EditCategory,
                        name: "editCategory"
                    },
                    {
                        path: "users",
                        component: UsersList,
                        name: "userList"
                    },
                    {
                        path: "newsletters",
                        component: newsletterList,
                        name: "newsletterList"
                    },
                    {
                        path: "newsletters/add",
                        component: addNewsletter,
                        name: "addNewsletter"
                    },
                    {
                        path: "newsletters/:id",
                        component: StatisticsNewsletter,
                        name: "statisticsNewsletter"
                    },
                    {
                        path: "newsletters/:id/edit",
                        component: EditNewsletter,
                        name: "editNewsletter"
                    },
                ]
            },
            {
                path: "login",
                component: Login,
                name: "login",
                meta: {
                    role: 'guest',
                }
            }
        ]

    },
]

export default routes;
